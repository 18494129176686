<template>
  <LayoutBox
    :loading="loading"
    title="今日预警网点大厅当前人数"
    type="frame_bottom"
  >
    <div
      slot="right"
      style="
        border: 1px solid;
        padding: 4px 10px;
        border-radius: 3px;
        cursor: pointer;
      "
      @click="
        $refs.earlyNumberTableDialog.openDialog({
          query: {
            warnType: '02',
          },
        })
      "
    >
      》更多
    </div>
    <div ref="lineRef" style="height: 100%"></div>
    <EarlyNumberTableDialog
      ref="earlyNumberTableDialog"
    ></EarlyNumberTableDialog>
    <WebsiteNumberDialog
      ref="websiteNumberDialog"
      @success="getData"
    ></WebsiteNumberDialog>
  </LayoutBox>
</template>

<script>
import { Column } from '@antv/g2plot';
import LayoutBox from '../components/LayoutBox.vue';
import WebsiteNumberDialog from './WebsiteNumberDialog.vue';
import EarlyNumberTableDialog from './EarlyNumberTableDialog.vue';
// import data from '@/utils/data';
export default {
  //组件注册
  components: {
    LayoutBox,
    WebsiteNumberDialog,
    EarlyNumberTableDialog,
  },
  //组件传值
  props: {
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      lineChart: null,
      replaceFields: {
        xField: 'text',
        yField: 'count',
      },
    };
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {
    refreshKey: {
      handler: 'getData',
    },
  },
  //DOM访问
  mounted() {
    this.lineChart = new Column(this.$refs.lineRef, {
      appendPadding: [8, 8, 0, 0],
      data: [],
      maxColumnWidth: 15,
      ...this.replaceFields,
      color: 'l(90) 0:#25E3FF 1:#0F57A1',
      columnStyle: {
        cursor: 'pointer',
      },
      xAxis: {
        label: {
          style: {
            fill: '#DEF8FF',
          },
        },
        tickLine: null,
        line: {
          style: {
            stroke: '#20579B',
            lineWidth: 1,
            opacity: 0.7,
            lineDash: [4, 5],
          },
        },
      },
      yAxis: {
        title: {
          position: 'end',
          autoRotate: false,
          text: '人',
          style: {
            fill: '#DEF8FF',
          },
        },
        min: 1,
        label: {
          style: {
            fill: '#DEF8FF',
          },
        },
        minInterval: 1,
        grid: {
          line: {
            style: {
              stroke: '#20579B',
              lineWidth: 1,
              opacity: 0.7,
              lineDash: [4, 5],
            },
          },
        },
      },
      slider: {
        start: 0,
        end: 1,
        textStyle: {
          fill: '#DEF8FF',
        },
      },
      tooltip: {
        showTitle: false,
        formatter: (column) => ({
          name: column[this.replaceFields.xField],
          value: `${column[this.replaceFields.yField]}人`,
        }),
      },
    });
    this.lineChart.render();
    this.getData();
    this.lineChart.on('plot:click', (args) => {
      if (args.data) {
        this.$refs.websiteNumberDialog.openDialog({
          row: args.data.data,
        });
      }
    });
  },
  //保存方法
  methods: {
    async getData() {
      try {
        this.loading = true;
        const data = await this.$http.get(
          `/${this.$businessISG}/monitor/home/io-stat`,
          {
            bankOutlets: '01',
            isFlowWarning: '01',
          }
        );
        if (data.code != 200) throw data.message;
        if (!data.data) throw '数据格式错误！';
        this.lineChart.changeData(data.data);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
