<template>
  <div
    class="layoutBox"
    :style="{
      background: `url(${require(`@/assets/screen/earlyWarning/${type}.png`)})`,
      backgroundSize: '100% 100%',
      padding: type == 'shape' ? '24px 44px' : '30px 80px 30px 125px',
    }"
  >
    <div
      style="display: flex; justify-content: space-between; margin-right: 12%"
    >
      <div style="display: flex" @click='titleClick'>
        <img src="@/assets/screen/earlyWarning/triangle.png" alt="triangle" />
        <div style="font-size: 20px; font-weight: bold; color: #ffffff">
          {{ title }}
        </div>
      </div>
      <div>
        <slot name="right"></slot>
      </div>
    </div>
    <div style="position: relative; flex: 1; overflow: hidden">
      <slot></slot>
      <v-overlay :opacity="1" absolute :value="loading">
        <v-progress-circular
          indeterminate
          color="#25E3FF"
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>

<script>
export default {
  //组件注册
  components: {},
  //组件传值
  props: {
    type: {
      type: String,
      default: 'shape',
    },
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {},
  //DOM访问
  mounted() {},
  //保存方法
  methods: {
    titleClick(){
      console.log(2);
      this.$emit('onTitleClick')
    },
  },
};
</script>

<style lang="scss" scoped>
.layoutBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
</style>
